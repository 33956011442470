import axios from '@/libs/axios'

export default {
  createBlog(data) {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('image', data.image)
    formData.append('credits', data.credits)
    formData.append('content', data.content)
    formData.append('storyType', data.type)

    return axios.post('/blogs', formData, { headers: { 'Content-Type': 'multipart/form-data', } })
  },
  updateBlog(uid, data) {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('image', data.image)
    formData.append('credits', data.credits)
    formData.append('content', data.content)
    formData.append('storyType', data.type)

    return axios.put('/blogs/' + uid, formData, { headers: { 'Content-Type': 'multipart/form-data', } })
  },
  deleteBlog(uid) {
    return axios.patch(`/blogs/${uid}/activation`)
  },
  getBlog(uid) {
    return axios.get('/blogs/' + uid)
  },
  getBlogs(type, page) {
    return axios.get('/blogs', {
      params: {
        storyType: type,
        page
      }
    })
  },
  uploadImage(image) {
    const formData = new FormData()
    formData.append('image', image)
    return axios.post('/blogs/image', formData, { headers: { 'Content-Type': 'multipart/form-data', } })
  },
  deleteImage(image) {
    return axios.delete('/blogs/image', {
      data: {
        image: image
      }
    })
  }
}
